h1 {
  width: 100vw;
  text-align: center;
  margin: 30px 0;
}

.camera {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 9;
  width: 600px;
  height: 400px;
  transform: translate(-50%, -50%);
  border-radius: 4px;
  overflow: hidden;
  box-shadow: #1976d2 0px 1px 4px, #1976d2 0px 0px 0px 3px;
}

.canvas {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 9;
  width: 600px;
  height: 400px;
  transform: translate(-50%, -50%);
  border-radius: 4px;
  overflow: hidden;
  box-shadow: #1976d2 0px 1px 4px, #1976d2 0px 0px 0px 3px;
}

