body {
  margin: 0;
  overflow: hidden;
  height: 100vh;
}
footer {
  position: absolute;
  width: 100%;
  bottom: 0;
  overflow: hidden;
}
